import { InputCpf, InputDefault, InputPhone } from '@/@presentation/components/form';
import { Row, Form as AntForm, DatePicker, Select, FormInstance } from 'antd';
import React from 'react';
import { styles } from '../../styles';
import { ProcedureContextData } from '@/@core/modules/procedure/domain/procedure.entities';
import { validateCPF } from '@/@core/modules/common/infra/validateCPF';
import { ConfirmationView } from '@/@presentation/modules/procedure/components/confirmationView';
import moment from 'moment';
import { InputRg } from '@/@presentation/components/form/InputRg';
import { useParams } from 'react-router-dom';

interface IProps {
  handleSearchPatientByCpf: (cpf: string) => void;
  states: ProcedureContextData['states'];
  isConfirm: boolean;
  form: FormInstance<any>;
  changehPatient: () => void;
  setHasError: (hasError: boolean) => void;
  calcAge: () => void;
}

export const Patient: React.FC<IProps> = (props) => {
  const [invalidCpf, setInvalidCpf] = React.useState(false)
  const { id } = useParams();

  const { calcAge } = props

  const cpfError = props.form.getFieldError('patientCpf');
  const hasCpfError = cpfError.length > 0

  const formItems = props.form.getFieldsValue()

  const handleChange = (cpf: string): void => {
    cpf = cpf.replace(/\D/g, '')
    if (cpf.length === 11) {
      const isInvalid = !validateCPF(cpf)
      props.handleSearchPatientByCpf(cpf)
      props.changehPatient()
      setInvalidCpf(isInvalid)
      props.setHasError(isInvalid)
      calcAge()
    }
  }

  React.useEffect(() => {
  props.states?.data?.patient?.birthday_date && calcAge()
  }, [props.states?.data?.patient])

  return (
    <div>
      <Row>
        <div style={{ marginRight: 20 }}>
          {props.isConfirm ? (
            <ConfirmationView item={{
              label: 'CPF',
              value: formItems.patientCpf || ''
            }} />
          ) :
            <InputCpf
              name="patientCpf"
              label="CPF"
              placeholder='Digite o CPF'
              disabled={id ? true : false}
              required
              onChange={(e) => handleChange(e.target.value)}
              error={{ isError: invalidCpf || hasCpfError, message: invalidCpf ? 'CPF invalido!' : cpfError[0] }}
            />}
        </div>
        {
          props.isConfirm ? (
            <ConfirmationView item={{
              label: 'Nome completo',
              value: formItems.patientFullName || ''
            }} />
          ) : (
            <InputDefault
              label="Nome completo"
              name="patientFullName"
              required={true}
              placeholder="Digite o nome completo do paciente"
              nativeProps={{
                style: {
                  width: 400,
                },
                disabled: props.isConfirm
              }}
            />
          )
        }
      </Row>
      <Row>
        <div style={{ marginRight: 20 }}>
          {props.isConfirm ? (
            <ConfirmationView item={{
              label: 'RG',
              value: formItems.patientRg || ''
            }} />
          ) : (
            <InputRg label="RG" name="patientRg" placeholder="Digite o RG do paciente" />
          )}
        </div>
        <div style={{ marginRight: 20 }}>
          {
            props.isConfirm ? (
              <ConfirmationView item={{
                label: 'Data de nascimento',
                value: moment(formItems.patientBirthDate?.toDate()).format('DD/MM/YYYY') || ''
              }} />
            ) : (
              <AntForm.Item
                name={'patientBirthDate'}
                label="Data de nascimento"
              >
                <DatePicker
                  disabled={props.isConfirm}
                  style={{ ...styles.defaultInput, borderRadius: 8 }}
                  format="DD/MM/YYYY"
                  onChange={calcAge}
                  onClick={calcAge}
                  onMouseLeave={calcAge}
                  onSelect={calcAge}
                  onKeyDown={calcAge}
                  allowClear={false}
                />
              </AntForm.Item>
            )
          }
        </div>
        <div style={{ marginRight: 20 }}>
          {
            props.isConfirm ? (
              <ConfirmationView item={{
                label: 'Idade',
                value: formItems.patientAge || ''
              }} />
            ) : (
              <InputDefault
                label="Idade"
                name="patientAge"
                placeholder=""
                nativeProps={{
                  style: {
                    width: 70,
                  },
                  disabled: true
                }}
              />
            )
          }
        </div>
        {
          props.isConfirm ? (
            <ConfirmationView item={{
              label: 'Sexo',
              value: formItems.patientGenre ? props.states.genres.filter(item => item.value === formItems.patientGenre)[0]?.label : ''
            }} />
          ) : (
            <AntForm.Item name="patientGenre" label="Sexo">
              <Select
                disabled={props.isConfirm}
                data-testid="select-procedure-patient-genre"
                style={{ ...styles.defaultInput, width: 140 }}
                placeholder="Selecione"
                showSearch
                filterOption={(input, option: any) => {
                  return (
                    option.value?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
                    option.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
                    option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                  );
                }}
              >
                {props.states.genres.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </AntForm.Item>
          )
        }
      </Row>
      <Row>
        <div style={{
          marginRight: 20,
        }}>
          {
            props.isConfirm ? (
              <ConfirmationView item={{
                label: 'E-mail',
                value: formItems.patientEmail || ''
              }} />
            ) : (
              <InputDefault
                label="E-mail"
                name="patientEmail"
                placeholder="Digite o e-mail do paciente"
                nativeProps={{
                  type: "email",
                  style: {
                    width: 300,
                  },
                  disabled: props.isConfirm
                }}
              />
            )
          }
        </div>
        <div
          style={{
            marginRight: 20,
          }}
        >
          {
            props.isConfirm ? (
              <ConfirmationView item={{
                label: 'Telefone',
                value: formItems.patientPhone || ''
              }} />
            ) : (
              <InputPhone name="patientPhone" label="Telefone" />
            )
          }
        </div>
        {
          props.isConfirm ? (
            <ConfirmationView item={{
              label: 'Nacionalidade',
              value: formItems.nationality || ''
            }} />
          ) : (
            <AntForm.Item name="nationality" label="Nacionalidade">
            <Select
              disabled={props.isConfirm}
              data-testid="select-doctorName"
              style={{ ...styles.defaultInput, minWidth: 260 }}
              placeholder="Selecione"
              showSearch
              filterOption={(input, option: any) => {
                return (
                  option.value?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
                  option.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
                  option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                );
              }}
            >
              {
                props.states.nationality.map((option) => (
                  <Select.Option key={option.nome_pais} value={option.nome_pais}>
                    {option.nome_pais}
                  </Select.Option>
                ))}
            </Select>
          </AntForm.Item>
          )
        }
      </Row>
      <Row>
        {
          props.isConfirm ? (
            <ConfirmationView item={{
              label: 'N° do documento internacional',
              value: formItems.patientInternationalDocument || ''
            }} />
          ) : (
            <InputDefault
              label="N° do documento internacional"
              name="patientInternationalDocument"
              placeholder="Documento internacional"
              nativeProps={{
                disabled: props.isConfirm
              }}
            />
          )
        }
      </Row>
    </div>
  )
};
